.noUnderline{
    text-decoration: none !important;
    
}

.upload{
    border:1px solid #fff;
    border-radius:4px;
    padding:4px;
    padding-left:3px;
}