.MuiDialog-paper-149  {
    margin:5px !important;
}

.MuiDialog-paper-140{
    margin:5px !important;
}

.MuiDialog-paper-148{
    margin:5px !important;
}

.MuiDialog-paper-147{
    margin:5px !important;
}

.MuiDialog-paper-146{
    margin:5px !important;
}

.MuiDialog-paper-145{
    margin:5px !important;
}

.MuiDialog-paper-144{
    margin:5px !important;
}

.MuiDialog-paper-143{
    margin:5px !important;
}

.MuiDialog-paper-142{
    margin:5px !important;
}

.MuiDialog-paper-141{
    margin:5px !important;
}


input[type=text]{
    padding: 12.5px 14px !important;
}

label{
    margin-top:-5px;
}

input[type=password]{
    padding: 12.5px 14px !important;
}
