body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  margin:auto;
}

.filepond--root{
  height: 76px !important;
}

@media screen and (min-width: 600px) {
  .right{
    margin-top:0px;  
    float:right;
    margin-left: 650px;
    text-align: center;
    top: 60px;
    position: fixed;
  }
  .rightContent {
    width: 300px,
  }

  .right2{
    margin-top:70px;  
    margin-left: 650px;
    text-align: center;
    top: 50px;
 
  }
}
@media screen and (max-width: 700px) {
  .right{
    display: none;
  }
  .right2{
    display: none;
  }
  .rightContent {
    display: none;
  }
}
.wrapper{
  max-width:1000px;
  margin:auto;
}

.header{
  position: fixed;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.MuiDialog-paper-149  {
    margin:5px !important;
}

.MuiDialog-paper-140{
    margin:5px !important;
}

.MuiDialog-paper-148{
    margin:5px !important;
}

.MuiDialog-paper-147{
    margin:5px !important;
}

.MuiDialog-paper-146{
    margin:5px !important;
}

.MuiDialog-paper-145{
    margin:5px !important;
}

.MuiDialog-paper-144{
    margin:5px !important;
}

.MuiDialog-paper-143{
    margin:5px !important;
}

.MuiDialog-paper-142{
    margin:5px !important;
}

.MuiDialog-paper-141{
    margin:5px !important;
}


input[type=text]{
    padding: 12.5px 14px !important;
}

label{
    margin-top:-5px;
}

input[type=password]{
    padding: 12.5px 14px !important;
}

.noUnderline{
    text-decoration: none !important;
    
}

.upload{
    border:1px solid #fff;
    border-radius:4px;
    padding:4px;
    padding-left:3px;
}
@media screen and (min-width: 600px) {
    .leftUpload {
        width:60% ;
        float: left;
        font-size: 12px;
    }
    .rightUpload {
        width:38% ;
        font-size: 12px;
        float: right;
        border-left:1px solid #cccccc;
    }
}

@media screen and (max-width: 599px) {
    .leftUpload {
        width:100% ;
       
        font-size: 12px;
    }
    .rightUpload {
        width:100% ;
        font-size: 12px;
       
    }
    
}

.dialogTitle{
    font-size: 12px;
    font-weight: bold;
}

.dialogContent{
    margin-top: -10px !important;
    font-size: 12px !important;
}
