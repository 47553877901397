.App {
  text-align: center;
  margin:auto;
}

.filepond--root{
  height: 76px !important;
}

@media screen and (min-width: 600px) {
  .right{
    margin-top:0px;  
    float:right;
    margin-left: 650px;
    text-align: center;
    top: 60px;
    position: fixed;
  }
  .rightContent {
    width: 300px,
  }

  .right2{
    margin-top:70px;  
    margin-left: 650px;
    text-align: center;
    top: 50px;
 
  }
}
@media screen and (max-width: 700px) {
  .right{
    display: none;
  }
  .right2{
    display: none;
  }
  .rightContent {
    display: none;
  }
}
.wrapper{
  max-width:1000px;
  margin:auto;
}

.header{
  position: fixed;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
