@media screen and (min-width: 600px) {
    .leftUpload {
        width:60% ;
        float: left;
        font-size: 12px;
    }
    .rightUpload {
        width:38% ;
        font-size: 12px;
        float: right;
        border-left:1px solid #cccccc;
    }
}

@media screen and (max-width: 599px) {
    .leftUpload {
        width:100% ;
       
        font-size: 12px;
    }
    .rightUpload {
        width:100% ;
        font-size: 12px;
       
    }
    
}

.dialogTitle{
    font-size: 12px;
    font-weight: bold;
}

.dialogContent{
    margin-top: -10px !important;
    font-size: 12px !important;
}